import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/article.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Dr  R L Sankha`}</strong>{` – the President of Spice Route - SAR visited Bangalore and AFPI hosted a get together for him with young graduates of FM at St Philomena’s Hospital on January 11, 2020. Dr Sankha spoke about Spice Route Movement and its activities and had a fruitful discussion about challenges of general practice in both countries. The session was attended by about 20 doctors and was live streamed on Facebook.`}</p>
    <hr></hr>
    <p>{`Meet and great with another dignitary `}<strong parentName="p">{`Dr Woolever`}</strong>{` - Director of FM residency, Darmouth, USA occurred on Feb 5, 2020 at Bangalore Baptist Hospital. Many young and experienced family physicians attended it and discussed about FM residency programs and opportunities for family physicians in both countries.`}</p>
    <hr></hr>
    <p>{`It was a great honor when the VC and team of `}<strong parentName="p">{`RGUHS`}</strong>{` invited AFPI to be the resource provider for conducting a webinar on “training of family physicians in practice management”. This hugely successful webinar was live on 27th April 2020 from the prestigious RGUHS campus. Dr Mohan Kubendra, Dr Roshni Jhanganguly, Dr Swapna Bhaskar and Dr Sowmya B Ramesh spoke on various aspects of COVID-19 in relation to clinical practice including teleconsultation, clinic management and PPEs for family physicians.`}</p>
    <hr></hr>
    <p>{`AFPI members led another `}<strong parentName="p">{`webinar`}</strong>{` for FPAB members on May 10, 2020 on “Clinic Management and PPEs for the practicing GP during COVID”. Dr Swapna Bhaskar and Dr Sowmya B Ramesh spoke on the topics respectively. The session was attended by over 200 doctors online and well appreciated.`}</p>
    <hr></hr>
    <p>{`The Public relations team of AFPI has been continuously putting up educational creative `}<strong parentName="p">{`flyers`}</strong>{` on COVID information to the public throughout March and April. The response and acceptance has been overwhelmingly positive.`}</p>
    <hr></hr>
    <p>{`World family doctors Day (May 19th) was celebrated by releasing a video of `}<strong parentName="p">{`patient testimonials`}</strong>{` on their family physicians and need for strong primary care in the society. It was really heartwarming to see the wholehearted appreciation of family doctors by the general public and it encourages us to serve our patients even more.`}</p>
    <p>{`On the special day, there was an exclusive `}<strong parentName="p">{`radio program`}</strong>{` dedicated to family doctors on Big FM. `}</p>
    <hr></hr>
    <p>{`Due to the pandemic and lockdown, the scheduled CME on “PVD in General Practice” which was to be organized on March 29th had to be postponed indefinitely. The finalized dates will be announced soon. `}</p>
    <hr></hr>
    <p>{`AFPI published a `}<strong parentName="p">{`position paper`}</strong>{` on "`}<a parentName="p" {...{
        "href": "http://www.jfmpc.com/article.asp?issn=2249-4863;year=2020;volume=9;issue=4;spage=1798;epage=1800;aulast=Kumar"
      }}>{`Ensuring continuity of care by small family practices and clinics in the primary care setting during COVID 19 pandemic 2020`}</a>{`" which is available online for all family physicians to view.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      